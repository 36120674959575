import React from "react";
import Layout from "layout";
import { Link } from "gatsby";
import Hero from "common/hero";
import Seo from "common/seo";
import { Row, Col } from "react-grid-system";
import { TypeA } from "common/callouts";
import heroImageRef from "images/baby/hero.webp";
import icon1 from "images/icons/icon-general-care.png";
import icon2 from "images/icons/icon-eczema-baby.png";
import icon3 from "images/icons/icon-diaper-rash.png";
import img1 from "images/callouts/cout-footer-resources.webp";
import img2 from "images/callouts/cout-therapeutic-footer.webp";
import img3 from "images/callouts/sunproducts2x.webp";

import "./baby.scss";

function BabyPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-baby"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Baby"
        pageDescription="Learn about Eucerin and Aquaphor therapeutic body care solutions for babies' skin"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container colored-boxes">
          <h2>What do your pediatric patients need help with?</h2>
          <Row>
            <Col sm={12} md={4}>
              <ColoredBox
                imgRef={icon1}
                imgAlt="GENERAL CARE"
                headText="GENERAL CARE"
                bodyText="Explore Solutions"
                boxLink="/baby/generalcare"
                headBgColor="#5DAEE1"
              />
            </Col>
            <Col sm={12} md={4}>
              <ColoredBox
                imgRef={icon2}
                imgAlt="ECZEMA"
                headText="ECZEMA"
                bodyText="Explore regimen"
                boxLink="/baby/eczema"
                headBgColor="#A70531"
              />
            </Col>
            <Col sm={12} md={4}>
              <ColoredBox
                imgRef={icon3}
                imgAlt="Diaper Rash"
                headText="Diaper Rash"
                bodyText="Explore formulation"
                boxLink="/baby/diaperrash"
                headBgColor="#363084"
              />
            </Col>
          </Row>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img1}
                  imgAlt="Resources for Skin Care"
                  caption="Find resources to support your body skin care recommendations"
                  buttonText="Browse resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img2}
                  imgAlt="Body Care Solutions"
                  caption="Clinically proven therapeutic solutions for body skin care"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img3}
                  imgAlt="Discover Sun Care"
                  caption="Sun protection for all skin types
and tones"
                  buttonText="Discover Sun Care"
                  buttonUrl="/sun"
                  imgStyles={{ height: 125 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}
export default BabyPage;

const MyHero = () => (
  <Hero className="inner-hero" bgImagePath={heroImageRef}>
    <div className="inner-hero-content">
      <p className="font-semi-bold">For baby skin care from top to bottom,</p>
      <h1>
        Recommend Aquaphor<sup>&reg;</sup> Baby and Eucerin<sup>&reg;</sup> Baby
      </h1>
      <p className="hero-reg-text">
        Specially formulated for babies' delicate skin, see how our
        pediatrician-recommended brands are designed to help treat a variety of
        baby skin care needs and help parents start healthy skin habits.
      </p>
    </div>
  </Hero>
);

const ColoredBox = ({
  imgRef,
  imgAlt,
  headText,
  bodyText,
  headBgColor,
  boxLink,
}) => (
  <div className="colored-box">
    <img src={imgRef} alt={imgAlt || ""} />
    <h3 style={{ backgroundColor: headBgColor }}>{headText}</h3>
    <Link to={boxLink}>
      <p className="box-text">{bodyText}</p>
    </Link>
  </div>
);
